/**
 * 验证整数类型数据
 * @param {String | Number} val 需要验证的值
 * @param {Array} param1 有效值的范围:[min,max]
 * @returns 验证是否通过
 */
export const validateInt = (val, [min, max]) => {
  try {
    const temp_val = Number(val);
    if (!Number.isInteger(temp_val)) {
      return false;
    }
    return temp_val >= (min ?? Number.min) && temp_val <= (max ?? Number.max);
  } catch (e) {
    return false;
  }
};
/**
 * 验证小数类型数据
 * @param {String | Number} val 需要验证的值
 * @param {Array} param1 有效值的范围:[min,max]
 * @param {Number} decimal 小数点后保留位数(默认:2)
 * @returns 验证是否通过
 */
export const validateFloat = (val, [min, max], decimal = 2) => {
  try {
    const temp_val = Number(val);
    if ((temp_val.toString().split('.')[1]?.length ?? 0) > decimal) {
      return false;
    }
    return temp_val >= (min ?? Number.min) && temp_val <= (max ?? Number.max);
  } catch (e) {
    return false;
  }
};
