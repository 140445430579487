<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-02 10:08:22
 * @ Description: 营销管理>标签分组>用户分组>用户分组编辑
 -->

<template>
  <!-- 表单样式  如果有温馨提示才加  with-warm-tip， 没有则不加 -->
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <div v-if="active === 0">
      <ykc-form-item label="分组名称" prop="groupName">
        <ykc-input
          v-model="ruleForm.groupName"
          maxlength="50"
          :placeholder="'请输入分组名称'"></ykc-input>
      </ykc-form-item>
      <ykc-form-item label="分组维度" prop="groupDimension">
        <ykc-radio
          :data="dictionary.userGroup"
          @change="changeGroupDimension"
          v-model="ruleForm.groupDimension"></ykc-radio>
      </ykc-form-item>
      <div v-if="ruleForm.groupDimension == '0'">
        <ykc-form-item label="数据维度" prop="dataDimension">
          <ykc-radio
            :data="dictionary.dataDimen"
            @change="groupDimensionChange"
            v-model="ruleForm.dataDimension"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="电站范围" prop="stationDimension">
          <ykc-radio
            :data="dictionary.stationDimen"
            v-model="ruleForm.stationDimension"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="选择电站" prop="stationList" v-if="ruleForm.stationDimension == '0'">
          <ykc-tree
            :v-model="ruleForm.stationList"
            multiple
            filterable
            :multiple-limit="50"
            placeholder="请选择电站"
            :data="treeData"
            :props="treeProps"
            ref="operaTree"
            :nodeKey="treeNodeKey"
            @check-change="handleTreeCheckChange"
            style="width: 100%"></ykc-tree>
        </ykc-form-item>
        <ykc-form-item
          label="选择电站分组"
          prop="groupList"
          v-if="ruleForm.stationDimension == '1'">
          <ykc-tree
            :v-model="ruleForm.groupList"
            multiple
            filterable
            ref="operaGroupTree"
            :multiple-limit="50"
            placeholder="请选择电站"
            :data="powerStationGroupList"
            :props="groupProps"
            :nodeKey="treeGroupNodeKey"
            @check-change="handleGroupTreeCheckChange"
            style="width: 100%"></ykc-tree>
        </ykc-form-item>
        <ykc-form-item label="时间区间" prop="labelTime">
          <ykc-date-picker
            limitDate="limitAfter"
            :limitToday="true"
            :limitOneYear="true"
            v-model="ruleForm.labelTime"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item :label="dataLabel" prop="condition"></ykc-form-item>
        <div class="limited-wrap">
          <div class="_line">
            <ykc-form-item
              prop="conditionMin"
              :rules="
                ruleForm.dataDimension == '2'
                  ? [this.addRules[0], this.addRules[2]]
                  : [this.addRules[0], this.addRules[1]]
              "
              label="">
              <ykc-input
                :placeholder="ruleForm.dataDimension == '2' ? '请输入两位精度数字' : '请输入整数'"
                :maxlength="ruleForm.dataDimension == '2' ? 9 : 6"
                v-model="ruleForm.conditionMin"></ykc-input>
            </ykc-form-item>
            <div>-</div>

            <ykc-form-item
              prop="conditionMax"
              :rules="
                ruleForm.dataDimension == '2'
                  ? [this.addRules[0], this.addRules[2]]
                  : [this.addRules[0], this.addRules[1]]
              "
              label="">
              <ykc-input
                :placeholder="ruleForm.dataDimension == '2' ? '请输入两位精度数字' : '请输入整数'"
                :maxlength="ruleForm.dataDimension == '2' ? 9 : 6"
                v-model="ruleForm.conditionMax"></ykc-input>
            </ykc-form-item>
          </div>
        </div>
      </div>
      <ykc-form-item
        class="file-error"
        :label="ruleForm.groupDimension == '1' ? '导入用户' : '导入电站'"
        prop="labelUpload"
        v-if="ruleForm.groupDimension == '1' || ruleForm.stationDimension == '2'">
        <ykc-upload
          type="xlsx"
          :size="5"
          ref="labelUpload"
          :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
          @handleRemove="handleRemove"
          @handleChange="labelUpload"
          :temText="ruleForm.groupDimension == '1' ? '用户标签导入模板.xlsx' : '电站导入模板.xlsx'"
          :temhref="temhref"></ykc-upload>
      </ykc-form-item>
    </div>
  </ykc-form>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { sessionGetItem } from '@/utils';
  import { getListNameById } from '@/utils/index';
  import { validateFloat } from '@/utils/validate';
  import { stationLabelApi, treeUtils, userLabelApi } from '@/service/apis';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    components: {},
    data() {
      return {
        treeProps: {
          label: 'operatorName',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        groupProps: {
          label: 'groupName',
          children: 'subNotes',
          disabled: 'disabled',
        },
        treeNodeKey: 'stationId',
        treeGroupNodeKey: 'id',
        contractList: [],
        powerStationList: [],
        treeData: [],
        powerStationGroupList: [],
        rangeValue: [],
        activeId: '',
        dictionary: {
          userGroup: [
            { id: '0', name: '按充电数据' },
            { id: '1', name: '批量导入' },
          ],
          dataDimen: [
            { id: '0', name: '充电量' },
            { id: '1', name: '充电次数' },
            { id: '2', name: '充电金额' },
          ],
          stationDimen: [
            { id: '0', name: '按电站' },
            { id: '1', name: '按电站分组' },
            { id: '2', name: '手动导入' },
          ],
          mathDimen: [
            { id: '0', name: '>=' },
            { id: '1', name: '<=>' },
            { id: '2', name: '=' },
          ],
        },
        addRules: [
          { required: true, message: '请填写数据区间', trigger: 'blur' },
          {
            pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
            message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error('', 999999),
            trigger: 'blur',
          },
          {
            pattern: regexpObj.regexp.input.chargeNumberType.regexp,
            message: regexpObj.regexp.input.chargeNumberType.errorTips.error('充电金额', 999999.99),
            trigger: 'blur',
          },
        ],
        dataLabel: '充电量',
        ruleForm: {
          id: null,
          groupName: '',
          startTime: '1',
          endTime: '',
          groupDimension: '1',
          groupDimensionMean: '',
          dataDimensionMean: '',
          stationDimension: '',
          stationDimensionMean: '',
          conditionMin: null,
          conditionMax: null,
          userCount: null,
          labelStatusMean: '',
          groupList: [],
          stationList: [],
          userList: null,
          createdBy: null,
          modifiedBy: null,
          modifiedTime: '',
          labelTime: [],
        },
        rules: {
          groupName: [
            { required: true, message: '请输入分组名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('分组名称'),
            },
          ],
          groupDimension: [{ required: true, message: '请选择分组维度', trigger: 'blur' }],
          dataDimension: [{ required: true, message: '请选择数据维度', trigger: 'blur' }],
          stationDimension: [{ required: true, message: '请选择电站范围', trigger: 'blur' }],
          stationList: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          groupList: [{ required: true, message: '请选择电站分组', trigger: 'blur' }],
          startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
          endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
          labelTime: [{ required: true, message: '请选择时间', trigger: 'blur' }],
          conditionMin: [{ required: true, message: '请填写数据区间', trigger: 'blur' }],
          conditionMax: [{ required: true, message: '请填写数据区间', trigger: 'blur' }],
          condition: [{ required: true, trigger: 'blur' }],
          labelUpload: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.labelUpload.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要导入的文件'));
                }
              },
            },
          ],
        },
      };
    },
    watch: {
      // eslint-disable-next-line func-names
      'ruleForm.stationId': function (_nVal) {
        userLabelApi.queryContractByStationId({ stationId: _nVal }).then(res => {
          this.contractList = res.body.map(o => {
            o.id = o.contractId;
            o.name = `采购单【${o.contractId}】 - ${o.customerName} - ${o.contractStatus}`;
            return o;
          });
        });
      },
      // eslint-disable-next-line func-names
      'ruleForm.dataDimension': function (value) {
        if (value === '0') {
          this.dataLabel = '充电量';
        }
        if (value === '1') {
          this.dataLabel = '充电次数';
        }
        if (value === '2') {
          this.dataLabel = '充电金额';
        }
      },
      // eslint-disable-next-line func-names
      'ruleForm.conditionMin': function (value) {
        this.ruleForm.condition = value;
      },
      // eslint-disable-next-line func-names
      'ruleForm.conditionMax': function (value) {
        this.ruleForm.condition = `${this.ruleForm.condition}-${value}`;
      },
    },
    computed: {
      formData() {
        const formData = {
          operatorId: sessionGetItem('accountId'),
          id: this.ruleForm.id,
          groupName: this.ruleForm.groupName,
          groupDimension: this.ruleForm.groupDimension,
          dataDimension: this.ruleForm.dataDimension,
          stationDimension: this.ruleForm.stationDimension,
          startTime: this.ruleForm.labelTime[0] ? `${this.ruleForm.labelTime[0]} 00:00:00` : '',
          endTime: this.ruleForm.labelTime[1] ? `${this.ruleForm.labelTime[1]} 00:00:00` : '',
          conditionType: this.ruleForm.conditionType,
          conditionMin: this.ruleForm.conditionMin,
          conditionMax: this.ruleForm.conditionMax,
          stationList: this.ruleForm.stationList,
          groupList: this.ruleForm.groupList,
          userList: this.ruleForm.userList,
        };

        return formData;
      },
      temhref() {
        return +this.ruleForm.groupDimension === 1
          ? `${process.env.VUE_APP_OSS}/resource/标签人员导入.xlsx`
          : `${process.env.VUE_APP_OSS}/resource/标签电站导入.xlsx`;
      },
    },
    created() {
      this.getStationTree();

      if (this.isEdit) {
        userLabelApi
          .detail({
            id: this.id,
          })
          .then(res => {
            this.ruleForm = {
              ...res,
            };
            this.ruleForm.labelTime = [this.ruleForm.startTime, this.ruleForm.endTime];
          });
      }
    },
    methods: {
      getListNameById,
      changeStation(val) {
        const item = this.powerStationList.find(o => o.stationId === val);
        this.ruleForm.operatorName = item?.operatorName ?? '';
        this.ruleForm.operatorAttribute = item?.operatorAttribute ?? '';
        this.ruleForm.stationStatusCode = item?.stationStatusCode ?? '';
      },
      validateRate(rule, value, callback, typeName, propKey) {
        const val = this.ruleForm.unifiedDiscount[propKey];
        const type = this.ruleForm.unifiedDiscount[`${propKey}Type`];
        if (!type) {
          callback(new Error(`请输入${typeName}优惠`));
        } else if (['', undefined].includes(val)) {
          callback(new Error(`请输入${typeName}优惠`));
        } else if (type === '1' && !validateFloat(val, [0, 999.9999], 4)) {
          callback(new Error(`请输入正确的${typeName}优惠(范围: 0-999.9999)`));
        } else if (type === '2' && !validateFloat(val, [0, 999.99], 2)) {
          callback(new Error(`请输入正确的${typeName}优惠(范围: 0-999.99)`));
        } else {
          callback();
        }
      },
      groupDimensionChange(e) {
        console.log(e);
      },
      changeGroupDimension() {
        this.$refs.labelUpload.fileList = [];
      },

      getStationTree() {
        treeUtils.operatorStations({}).then(res => {
          this.powerStationList = res;
          this.treeProps = {
            label: 'stationName',
            children: 'stationTreeVOList',
            disabled: 'disabled',
          };
          this.treeNodeKey = 'stationId';
          const listData = res || [];
          const kayMap = { operatorName: 'stationName', operatorId: 'stationId' };
          const setEmpty = (data, keyMap) => {
            const objs = Object.keys(data).reduce((newData, key) => {
              const newKey = keyMap[key] || key;
              newData[newKey] = data[key];
              return newData;
            }, {});
            return objs;
          };
          this.treeData = listData.map(item => {
            return setEmpty(item, kayMap);
          });
        });
        treeUtils.operatorStationGroup({}).then(res => {
          this.powerStationGroupList = res;
        });
      },
      handleTreeCheckChange() {
        this.ruleForm.stationList = this.$refs.operaTree.getCheckedKeys(true);
      },
      handleGroupTreeCheckChange() {
        this.ruleForm.groupList = this.$refs.operaGroupTree.getCheckedKeys(true);
        console.log(JSON.stringify(this.ruleForm.groupList));
      },
      labelUpload(file, fileList, isCheck) {
        // console.log(file, fileList, isCheck);
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
          };
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          if (this.ruleForm.groupDimension === '1') {
            userLabelApi.uploadFile(formData).then(res => {
              console.log(JSON.stringify(res));
              this.ruleForm.userList = res.userList;
            });
          } else {
            stationLabelApi.uploadFile(formData).then(res => {
              console.log(JSON.stringify(res));
              this.ruleForm.stationList = res.stationList;
            });
          }
        }
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      submitForm() {
        if (
          (Number(this.ruleForm.stationDimension) === 1 && this.ruleForm.groupList.length === 0) ||
          (Number(this.ruleForm.groupDimension) === 1 &&
            (!this.ruleForm.userList || this.ruleForm.userList.length === 0))
        ) {
          this.$message({
            message: '数据为空不允许提交',
            type: 'success',
          });
          return null;
        }
        console.log(JSON.stringify(this.formData));
        this.ruleForm.condition = [this.ruleForm.conditionMin, this.ruleForm.conditionMax];
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.isEdit) {
              userLabelApi.edit(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            } else {
              (Number(this.ruleForm.groupDimension) === 0
                ? userLabelApi.addByCharge(this.formData)
                : userLabelApi.add(this.formData)
              ).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    flex-direction: row;
    margin-top: -10px;
    & > * {
      margin-right: 5px;
    }
  }
</style>
